import React from "react"
import { graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import List from '../components/list'
import Layout from '../components/layout'
import Footer from '../components/footer'
import Topo from '../styles/topo'

import { Content, PageHeading, JumpTo, SectionHeading, Resources, Main } from '../styles'


export default function PageTemplate({ data: { links = [], mdx } }) {
  return (
    <Layout>
        <Topo><PageHeading>{mdx.frontmatter.title}</PageHeading></Topo>
      <Content>
      <Main>
        {links.edges.length > 0 && <JumpTo><a href="#related">{links.edges.length} resources</a> below &darr;</JumpTo>}
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </Main>
      </Content>
      {links.edges.length > 0 && <Resources>
      <Content>
        <SectionHeading id="related">Related Resources</SectionHeading>
        <List list={links.edges.filter(e => e.node.data.type !== "book").map(e => Object.assign({}, e.node.data, {tags: e.node.tags}))} />
      </Content>
      </Resources>}
      {links.edges.filter(e => e.node.data.type === "book").length > 0 && (
      <Content>
        <PageHeading>Books on this topic</PageHeading>
        <List list={links.edges.filter(e => e.node.data.type === "book").map(e => Object.assign({}, e.node.data, {tags: e.node.tags}))} showFilter={false} />
      </Content>)}
      <Footer />
    </Layout>
  )
}
export const pageQuery = graphql`
  query PageQuery($id: String, $tag: [String]) {
    mdx(id: { eq: $id }) {
      id
      fields {
          slug
          tag
      }
      frontmatter {
        title
      }
      body
    }
    links: allPrismicLink(filter : { tags: { in:  $tag }}, sort: {fields: last_publication_date, order: DESC}) {
        totalCount
        edges {
          node {
            tags
            data {
              url
              type
              title
              publisher
              description
              author
            }
          }
        }
      }
    }
`